export default {
  '/api': {
    // target: 'https://testapi.wzyanche.com/',
    // target: 'https://bd.wzyanche.com',
    target: 'https://bdtest.wzyanche.com',
    changeOrigin: true,
  },
  '/data': {
    target: 'https://bd.wzyanche.com',
    // target: 'https://bdtest.wzyanche.com',
    changeOrigin: true,
    pathRewrite: { '^/data': '' },
  },
  '/coupon': {
    target: 'https://testapi.wzyanche.com',
    changeOrigin: true,
    pathRewrite: { '^/coupon': '' },
  },
  '/usersync/': {
    target: 'http://124.71.167.253:9998/',
    changeOrigin: true,
    pathRewrite: { '^/usersync': '' },
  }
}