import Toast from 'antd-mobile/es/components/toast';
import { FC, useEffect, useRef, useState } from 'react';
import { history, useLocation } from 'umi';
import Navbar from '../utils/Navbar';
import styles from './index.less';
import initMerchant from './img/initMerchant.png';
import { style } from '@mui/system';
import test from './img/test.jpg';
import {
  Button,
  Card,
  Checkbox,
  Form,
  ImageViewer,
  Input,
  Modal,
  Popup,
  Stepper,
  TextArea,
} from 'antd-mobile';
import { dateFormat } from '../utils/util';
import { config, getRecord, modifyPrice, regionConfirm } from './service';
import {
  cancelOrder,
  confirmCouponAgain,
  financialConfirm,
  financialRefuse,
  getWxAccessToken,
  uploadPaymentProof,
} from '../collect/service';
import { ReceivePaymentOutline } from 'antd-mobile-icons';
import { isIOS } from '../collect';
import 微信 from './img/微信.png';
import 支付宝 from './img/支付宝.png';
// @ts-ignore
import QRCode from 'qrcode.react';
// import config from 'https://bd.wzyanche.com/config';
import { CountDown } from '@nutui/nutui-react';
import dayjs from 'dayjs';
const CollectionDetails: FC<any> = (props) => {
  const location = useLocation();
  const [giftFlag, setGiftFlag] = useState(false);
  const [details, setDetails] = useState<any>([]);
  const [value, setValue] = useState<any>([]);
  const [noteList, setNoteList] = useState<any>([]);
  const [upCount, setUpCount] = useState<number>(0);
  const [QRCodeVisible, setQRCodeVisible] = useState<boolean>(false);
  const [visibleDetete, setVisibleDetete] = useState<boolean>(false);
  const [auditsVisible, setAuditsVisible] = useState<boolean>(false);
  const [credentialsVisible, setcredentialsVisible] = useState<boolean>(false);
  const [modifyTheTotalPriceVisible, setModifyTheTotalPriceVisible] =
    useState<boolean>(false);
  const [QRFlag, setQRFlag] = useState<boolean>(false);
  const [dateTime, setDateTime] = useState<number>(0);
  const [totalPriceNew, setTotalPriceNew] = useState<number>(-1);
  const [totalPrice, setTotalPrice] = useState<number>(-1);
  const [forms] = Form.useForm();
  const [formGift] = Form.useForm();
  const [formDelete] = Form.useForm();
  const [formTotalPrice] = Form.useForm();
  const [formFinancialRefuse] = Form.useForm();
  const dataFlag: any = location.state;
  const [Images, setImages] = useState<string>('');
  const [imageVisible, setImageVisible] = useState<boolean>(false);
  const position = localStorage.getItem('position');
  const userName = localStorage.getItem('name');
  const batchBuyAuthority = localStorage.getItem('batchBuyAuthority');
  const stateRef = useRef({
    endTime: Date.now() + 60 * 1000,
  });
  const detail = (batchBuyNo: string) => {
    getRecord({
      batchBuyNo: batchBuyNo,
    }).then((res) => {
      if (res.retCode === 0) {
        setDetails(res.data);
        let list: number[] = [];
        let totalNum = 0;
        if (res.data.details && res.data.details.length > 0) {
          res.data.details.map((item: any, index: number) => {
            if (item.giftFlag && item.giftRemark) {
              list.push(item);
            }
            if (!item.giftFlag) {
              totalNum = totalNum + item.price * item.num;
            }
          });
        }
        console.log(totalNum);
        setTotalPrice(totalNum);
        setNoteList(list);
      }
    });
    config().then((res: any) => {
      console.log(res.upCount);
      setUpCount(res.upCount);
    });
  };
  const onDelete = (v: any) => {
    formDelete
      .validateFields()
      .then(() => {
        onDeleteOrder(v);
      })
      .catch(() => {
        setVisibleDetete(true);
      });
  };
  const onDeleteOrder = (v: any) => {
    const values = formDelete.getFieldsValue();
    cancelOrder({ batchBuyNo: v, remark: values.forms })
      .then((res: any) => {
        if (res.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '已取消订单',
          });
          history.goBack();
        } else {
          Toast.show({
            icon: 'fail',
            content: res.retMsg,
          });
        }
      })
      .catch(() => {
        Toast.show({
          icon: 'fail',
          content: '订单取消失败',
        });
      });
  };
  const onFinish = () => {
    const values = formGift.getFieldsValue();
    const value = forms.getFieldsValue();
    const data: any = [];
    if (details.details && details.details.length > 0) {
      details.details.map((item: any, index: number) => {
        if (!item.recommendFlag) {
          if (values[`num${index}`]) {
            data[index] = {
              rowId: item.rowId,
              num: values[`num${index}`],
            };
          } else if (values[`price${index}`]) {
            data[index] = {
              rowId: item.rowId,
              price: values[`price${index}`],
              num: item.num,
            };
          } else {
            data[index] = {
              rowId: item.rowId,
              num: item.num,
              price: item.price,
            };
          }
        }
      });
      if (value.rowId && value.num) {
        data.push({
          rowId: value.rowId,
          num: value.num,
        });
      }
      console.log(data);
      regionConfirm({ batchBuyNo: dataFlag.batchBuyNo, details: data })
        .then((msg) => {
          if (msg.retCode === 0) {
            Toast.show({
              icon: 'success',
              content: '审核通过',
            });
            setAuditsVisible(true);
            history.goBack();
          } else {
            Toast.show({
              icon: 'fail',
              content: msg.retMsg,
            });
          }
        })
        .catch((error) => {
          Toast.show({
            icon: 'fail',
            content: '审核失败',
          });
        });
    }
  };
  const onRecurl = (batchBuyNo: string) => {
    confirmCouponAgain({ batchBuyNo: batchBuyNo })
      .then((res: any) => {
        if (res.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '发放成功',
          });
        } else {
          Toast.show({
            icon: 'fail',
            content: res.retMsg,
          });
        }
      })
      .catch(() => {
        Toast.show({
          icon: 'fail',
          content: '发放失败',
        });
      });
  };
  const wxShareConfig = async (batchBuyNo: any) => {
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/see"
    });
    if (msg.retCode === 0) {
      window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: [
          'chooseImage',
          'getLocalImgData',
          'previewImage',
          'uploadImage',
          'downloadImage',
        ],
      });
      window.wx.agentConfig({
        corpid: 'ww4168efadaace928a', // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000013', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['uploadImage'], //必填，传入需要使用的接口名称
        success: function () {
          // 回调
        },
        fail: function (res: { errMsg: string | string[] }) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      window.wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'normal', //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: function (res: { localIds: any }) {
          if (res.localIds <= 0) {
            return;
          }
          var localIds = res.localIds[0];
          window.wx.uploadImage({
            localId: localIds.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res: { serverId: any }) {
              var serverId = res.serverId;
              uploadPaymentProof({
                mediaId: serverId,
                batchBuyNo: batchBuyNo,
              })
                .then((msg: any) => {
                  if (msg.retCode === 0) {
                    Toast.show({
                      icon: 'success',
                      content: '上传成功',
                    });
                  } else {
                    Toast.show({
                      icon: 'fail',
                      content: msg.retMsg,
                    });
                  }
                })
                .catch(() => {
                  Toast.show({
                    icon: 'fail',
                    content: '上传失败',
                  });
                });
            },
            errMsg: function () {
              Toast.show({
                icon: 'fail',
                content: '上传失败',
              });
              return;
            },
          });
        },
      });
    }
  };
  const onFinancialConfirm = (batchBuyNo: any) => {
    financialConfirm({ batchBuyNo: batchBuyNo })
      .then((res) => {
        if (res.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '审核通过',
          });
          history.goBack();
        } else {
          Toast.show({
            icon: 'fail',
            content: res.retMsg,
          });
        }
      })
      .catch(() => {
        Toast.show({
          icon: 'fail',
          content: '审核失败',
        });
      });
  };
  const onFinancialRefuse = (batchBuyNo: any) => {
    const remark = formFinancialRefuse.getFieldsValue();
    financialRefuse({ batchBuyNo: batchBuyNo, remark: remark.forms })
      .then((res) => {
        if (res.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '已驳回',
          });
          history.goBack();
        } else {
          Toast.show({
            icon: 'fail',
            content: res.retMsg,
          });
        }
      })
      .catch(() => {
        Toast.show({
          icon: 'fail',
          content: '驳回失败',
        });
      });
  };
  useEffect(() => {
    if (dataFlag && dataFlag.details) {
      dataFlag.details.map((item: any) => {
        if (item.giftFlag) {
          setGiftFlag(true);
        }
      });
      if (dataFlag.batchBuyNo) {
        detail(dataFlag.batchBuyNo);
      }
    }
    const day = Date.now();
    const days = Date.parse(dataFlag.createdAt);
    const dayN = 60 * 60 * 1000 - (day - days);
    console.log(stateRef, 60 * 60 * 1000 - dayN);
    setDateTime(dayN);
  }, []);
  const onValuesChange = () => {
    const values = formGift.getFieldsValue();
    console.log(values);

    if (details.details && details.details.length > 0) {
      let totalPrice = 0;
      details.details.map((item: any, index: number) => {
        if (values[`price${index}`]) {
          totalPrice =
            parseFloat(values[`price${index}`]) * item.num + totalPrice;
        }
      });
      setTotalPriceNew(totalPrice);
    }
  };
  const stateMap: any = {
    0: '待收款',
    1: '已收款',
    2: '已取消',
    3: '已退款',
    4: '订单超时',
    5: '财务待确认',
    6: '财务对付款凭证驳回',
    7: '待审核',
  };
  const payTypeMap: any = { 1: '微信支付', 2: '支付宝' };
  return (
    <div className={styles.page}>
      <Navbar name={'订单详情'}></Navbar>
      <div className={styles.title}>
        {stateMap[details.payStatus]}
        {details.payStatus === 0 && (
          <CountDown
            style={{ marginLeft: '10px', fontSize: '15px', fontWeight: '400' }}
            endTime={Date.now() + dateTime}
            format="mm分ss秒"
          />
        )}
      </div>
      <div className={styles.collect_detail}>
        <div className={styles.company}>
          <img
            height={40}
            src={details?.doorHeadPhoto ? details?.doorHeadPhoto : initMerchant}
            alt=""
          />
          <div className={styles.company_name}>
            <div>
              {details?.memberName}{' '}
              <a style={{ color: 'red', fontSize: '12px', fontWeight: '400' }}>
                {details.onlyRecordFlag ? '仅记录' : '发卷'}
              </a>
              <a style={{ color: 'red', fontSize: '12px', fontWeight: '400' }}>
                （{details.purchase ? '复购' : '新购'}
                {details.imageShop && '形象店'}）
              </a>
              {`${details.chargeType}` === '0' && (
                <a
                  style={{
                    color: 'red',
                    marginLeft: '5px',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                >
                  市场收费
                </a>
              )}
            </div>
            <span>
              {details?.memberPhone || '--'} |{' '}
              {details?.memberMarketName || '--'}
            </span>
          </div>
        </div>
        {!details.tasteFlag && (
          <Card title={'集采产品'}>
            {details &&
              details.details &&
              details.details.map((item: any, index: any) => {
                if (!item.giftFlag && !item.recommendFlag && !item.tasteFlag) {
                  return (
                    <div className={styles.test}>
                      <div className={styles.test_order}>
                        <img
                          style={{
                            width: `${item.type === 99 ? '15%' : '35%'}`,
                          }}
                          src={item.imgUrl ? item.imgUrl : test}
                          alt=""
                        />
                        <div className={styles.test_order_0}>
                          <div className={styles.test_order_title}>
                            {item.couponName ? item.couponName : '--'}
                          </div>
                          {item.type != 99 && (
                            <>
                              <div className={styles.test_order_tabs}>
                                <span>终身回购</span>
                                <span>检测报告</span>
                              </div>
                              <div className={styles.test_order_result}>
                                适用范围：{item.useDesc ? item.useDesc : '--'}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className={styles.test_detail}>
                          <div>数量</div>
                          <div>{item.num}</div>
                        </div>
                        {((position && position.indexOf('大区') > -1) ||
                          (batchBuyAuthority && batchBuyAuthority === '99')) &&
                        details.payStatus === 7 ? (
                          <Form form={formGift} layout="horizontal" mode="card">
                            <Form.Item
                              label={<>单价(￥{item.price})</>}
                              name={`price${index}`}
                              className={styles.form_price}
                              initialValue={item.price}
                              rules={[
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.reject(
                                        new Error('请输入单价'),
                                      );
                                    }
                                    if (
                                      Number.isFinite(Number(value)) === false
                                    ) {
                                      return Promise.reject(
                                        new Error('不可输入文字！！'),
                                      );
                                    }
                                    if (value < 0) {
                                      return Promise.reject(
                                        new Error('单价不可小于0！！！'),
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                  required: true,
                                }),
                              ]}
                            >
                              <Input
                                placeholder="请输入单价"
                                onChange={onValuesChange}
                              />
                            </Form.Item>
                          </Form>
                        ) : (
                          <div className={styles.test_detail}>
                            <div>单价</div>
                            <div>￥{item.price}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            <div className={styles.footer}>
              <div className={styles.test_detail}>
                <div></div>
                <div>
                  总价：
                  <span>
                    ￥{totalPriceNew != -1 ? totalPriceNew : details.price}
                  </span>{' '}
                  {(details.payStatus === 0 ||
                    details.payStatus === 5 ||
                    details.payStatus === 6) && (
                    <a
                      onClick={() => {
                        Modal.confirm({
                          title: '修改订单总价',
                          bodyClassName: styles.totalPrice,
                          content: (
                            <>
                              <div style={{ width: '100%' }}>
                                <Form
                                  form={formTotalPrice}
                                  layout="horizontal"
                                  mode="card"
                                >
                                  <Form.Item
                                    label={'总价：'}
                                    name={`price`}
                                    className={styles.form_price}
                                    initialValue={
                                      totalPriceNew != -1
                                        ? totalPriceNew
                                        : details.price
                                    }
                                    rules={[
                                      () => ({
                                        validator(_, value) {
                                          if (!value) {
                                            return Promise.reject(
                                              new Error('请输入修改总价'),
                                            );
                                          }
                                          if (
                                            Number.isFinite(Number(value)) ===
                                            false
                                          ) {
                                            return Promise.reject(
                                              new Error('不可输入文字！！'),
                                            );
                                          }
                                          if (value < 0) {
                                            return Promise.reject(
                                              new Error('总价不可小于0！！！'),
                                            );
                                          }
                                          if (
                                            batchBuyAuthority != '99' &&
                                            value < totalPrice
                                          ) {
                                            return Promise.reject(
                                              new Error(
                                                '修改价格不可小于订单总价！！！',
                                              ),
                                            );
                                          }
                                          return Promise.resolve();
                                        },
                                        required: true,
                                      }),
                                    ]}
                                  >
                                    <Input placeholder="请输入修改总价" />
                                  </Form.Item>
                                </Form>
                              </div>
                            </>
                          ),
                          onConfirm: async () => {
                            const values = formTotalPrice.getFieldsValue();
                            await modifyPrice({
                              batchBuyNo: dataFlag.batchBuyNo,
                              price: values.price,
                            })
                              .then((res) => {
                                if (res.retCode === 0) {
                                  Toast.show({
                                    icon: 'success',
                                    content: '修改成功',
                                  });
                                  setTotalPriceNew(values.price);
                                  history.go(0);
                                } else {
                                  Toast.show({
                                    icon: 'fail',
                                    content: res.retMsg,
                                  });
                                }
                              })
                              .catch(() => {
                                Toast.show({
                                  icon: 'fail',
                                  content: '修改失败',
                                });
                              });
                          },
                        });
                      }}
                    >
                      修改总价
                    </a>
                  )}
                </div>
              </div>
            </div>
          </Card>
        )}
        {details.tasteFlag && (
          <Card title={'体验产品'}>
            {details &&
              details.details &&
              details.details.map((item: any) => {
                return (
                  <div className={styles.test}>
                    <div>
                      <div className={styles.test_detail}>
                        <div>{item.couponName}</div>
                        <div>x{item.num}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Card>
        )}
        {giftFlag && (
          <Card title={'赠送产品'}>
            {details &&
              details.details &&
              details.details.map((item: any, index: number) => {
                if (item.giftFlag) {
                  return (
                    <div className={styles.test}>
                      <div className={styles.test_order}>
                        <img src={item.imgUrl ? item.imgUrl : test} alt="" />
                        <div className={styles.test_order_0}>
                          <div className={styles.test_order_title}>
                            {item.couponName ? item.couponName : '--'}{' '}
                          </div>
                          <div className={styles.test_order_tabs}>
                            <span>终身回购</span>
                            <span>检测报告</span>
                          </div>
                          <div className={styles.test_order_result}>
                            适用范围：{item.useDesc ? item.useDesc : '--'}
                          </div>
                        </div>
                      </div>
                      <div>
                        {((position && position.indexOf('大区') > -1) ||
                          (batchBuyAuthority && batchBuyAuthority === '99')) &&
                        details.payStatus === 7 ? (
                          <Form form={formGift} layout="horizontal" mode="card">
                            <Form.Item
                              label="数量"
                              name={`num${index}`}
                              className={styles.form_num}
                              initialValue={item.num}
                            >
                              {userName === '朱葛' ? (
                                <Stepper min={0} />
                              ) : (
                                <Stepper min={0} max={item.num + upCount} />
                              )}
                            </Form.Item>
                          </Form>
                        ) : (
                          <div className={styles.test_detail}>
                            <div>数量</div>
                            <div>{item.num}</div>
                          </div>
                        )}
                        {item.giftRemark && (
                          <div className={styles.test_detail}>
                            <div>备注</div>
                            <div style={{ color: 'red' }}>
                              {' '}
                              <a style={{ marginRight: '5px' }}>
                                {item.giftRemark}
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
          </Card>
        )}
        {details &&
          details.details &&
          details.details.map((item: any, index: number) => {
            if (item.recommendFlag) {
              let num = 0;
              if (item.sameCityFlag) {
                num = 1;
              } else {
                num = 5;
              }
              return (
                <Card title={'推荐商户信息'}>
                  <div className={styles.test}>
                    <div className={styles.test_detail}>
                      <div>推荐商户</div>
                      <div>
                        {item.memberName}{' '}
                        <a>({item.sameCityFlag ? '同城' : '异地'})</a>
                      </div>
                    </div>
                    <div className={styles.test_detail}>
                      <div>转介绍商户手机号</div>
                      <div>{item.memberPhone}</div>
                    </div>
                    <div>
                      {((position && position.indexOf('大区') > -1) ||
                        (batchBuyAuthority && batchBuyAuthority === '99')) &&
                      details.payStatus === 7 ? (
                        <Form form={forms} layout="horizontal" mode="card">
                          <Form.Item
                            style={{ display: 'none' }}
                            label={item.couponName}
                            name={'rowId'}
                            className={styles.form_num}
                            initialValue={item.rowId}
                          >
                            <Stepper min={0} max={num} />
                          </Form.Item>
                          <Form.Item
                            label={item.couponName}
                            name={'num'}
                            className={styles.form_num}
                            initialValue={item.num}
                          >
                            <Stepper min={0} max={num} />
                          </Form.Item>
                        </Form>
                      ) : (
                        <div className={styles.test_detail}>
                          <div>{item.couponName}</div>
                          <div>{item.num}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              );
            }
          })}
        <Card title={'订单信息'}>
          <div className={styles.test}>
            {details.memberPhone && (
              <div>
                <div className={styles.test_detail}>
                  <div>商户手机号</div>
                  <div>{details.memberPhone}</div>
                </div>
              </div>
            )}
            <div>
              <div className={styles.test_detail}>
                <div>订单编号</div>
                <div>{details.batchBuyNo}</div>
              </div>
            </div>
            <div>
              <div className={styles.test_detail}>
                <div>下单时间</div>
                <div>
                  {details.createdAt
                    ? dateFormat(details.createdAt, 'yyyy-MM-dd hh:mm:ss')
                    : '-'}
                </div>
              </div>
            </div>
            <div>
              <div className={styles.test_detail}>
                <div>下单BD</div>
                <div>{details.bdName ? details.bdName : '-'}</div>
              </div>
            </div>
            {details.payStatus === 2 && (
              <div>
                <div className={styles.test_detail}>
                  <div>取消原因</div>
                  <div>{details.remark}</div>
                </div>
              </div>
            )}
            {details.payStatus === 6 && details.financialRefuseRemark && (
              <div>
                <div className={styles.test_detail}>
                  <div>驳回原因</div>
                  <div>{details.financialRefuseRemark}</div>
                </div>
              </div>
            )}
            {details.payType && (
              <div>
                <div className={styles.test_detail}>
                  <div>支付方式</div>
                  <div>{payTypeMap[details.payType]}</div>
                </div>
              </div>
            )}
            {/* {`${details.imageShop}` != "" && (
              <div className={styles.test_detail}>
                <div>
                  申请形象店
                </div>
                <div>
                  {details.imageShop ? "是" : "否"}
                </div>
              </div>
            )} */}
          </div>
        </Card>
      </div>
      {details.payStatus != 4 && (
        <div className={styles.footer_button}>
          {details.payStatus === 1 && details.batchBuyStatus != 1 && (
            <div
              className={styles.footer_button_0}
              onClick={() => {
                Modal.confirm({
                  content: '确重新发卷',
                  onConfirm: () => {
                    onRecurl(details.batchBuyNo);
                  },
                });
              }}
            >
              重新发卷
            </div>
          )}
          {details.imgProof && (
            <div
              className={styles.footer_button_0}
              onClick={() => {
                // Modal.show({
                //   closeOnMaskClick: true,
                //   content: (
                //     <div>
                //       <img style={{maxHeight:"50vh",width:"100%"}} src={details.imgProof} onClick={()=>{
                //         setImages(details.imgProof)
                //         setImageVisible(true)
                //       }}  />
                //       <div className={styles.batchBuyNo}>订单编号:{details.batchBuyNo}</div>
                //     </div>
                //   )
                // });
                // setImages(details.imgProof);
                // setImageVisible(true);
                setcredentialsVisible(true);
              }}
            >
              查看凭证
            </div>
          )}
          {details.payStatus === 0 && (
            <>
              <div
                className={styles.footer_button_1}
                onClick={() => {
                  wxShareConfig(details.batchBuyNo);
                }}
              >
                上传凭证
              </div>
              <div
                className={styles.footer_button_0}
                onClick={() => {
                  setVisibleDetete(true);
                }}
              >
                取消订单
              </div>
              <div
                className={styles.footer_button_1}
                onClick={() => {
                  setQRCodeVisible(true);
                }}
              >
                <ReceivePaymentOutline fontSize={16} /> <span>收款码</span>
              </div>
            </>
          )}
          {((position && position.indexOf('大区') > -1) ||
            (batchBuyAuthority && batchBuyAuthority === '99')) &&
            details.payStatus === 7 && (
              <>
                <div
                  className={styles.footer_button_1}
                  onClick={() => {
                    if (noteList && noteList.length > 0) {
                      setAuditsVisible(true);
                    } else {
                      onFinish();
                    }
                  }}
                >
                  审核通过
                </div>
                <div
                  className={styles.footer_button_0}
                  onClick={() => {
                    setVisibleDetete(true);
                  }}
                >
                  审核不通过
                </div>
              </>
            )}
          {batchBuyAuthority &&
            batchBuyAuthority === '99' &&
            details.payStatus === 5 && (
              <div
                className={styles.footer_button_1}
                onClick={() => {
                  Modal.confirm({
                    title: '确认审核通过？',
                    closeOnMaskClick: true,
                    content: (
                      <div className={styles.batchBuyNo}>
                        <img
                          onClick={() => {
                            setImages(details.imgProof);
                            setImageVisible(true);
                          }}
                          style={{ maxHeight: '50vh', width: '100%' }}
                          src={details.imgProof}
                          alt=""
                        />
                        <div>订单编号:{details.batchBuyNo}</div>
                      </div>
                    ),
                    onConfirm: () => {
                      onFinancialConfirm(details.batchBuyNo);
                    },
                  });
                }}
              >
                财务审核
              </div>
            )}
          {batchBuyAuthority &&
            batchBuyAuthority === '99' &&
            details.payStatus === 5 && (
              <div
                className={styles.footer_button_0}
                onClick={() => {
                  Modal.confirm({
                    title: '确认驳回订单？',
                    closeOnMaskClick: true,
                    content: (
                      <div className={styles.batchBuyNo}>
                        <img
                          onClick={() => {
                            setImages(details.imgProof);
                            setImageVisible(true);
                          }}
                          style={{ maxHeight: '30vh', width: '100%' }}
                          src={details.imgProof}
                          alt=""
                        />
                        <div>订单编号:{details.batchBuyNo}</div>
                        <Form form={formFinancialRefuse}>
                          <Form.Item
                            name="forms"
                            rules={[
                              {
                                required: true,
                                message: '请输入取消原因',
                              },
                            ]}
                            className={styles.form_item}
                          >
                            <TextArea
                              placeholder="请输入取消原因"
                              maxLength={100}
                              rows={5}
                              showCount
                              onChange={(v) => {
                                console.log(v);
                              }}
                            />
                          </Form.Item>
                        </Form>
                      </div>
                    ),
                    onConfirm: () => {
                      onFinancialRefuse(details.batchBuyNo);
                    },
                  });
                }}
              >
                驳回
              </div>
            )}
        </div>
      )}
      <Popup
        visible={QRCodeVisible}
        onMaskClick={() => {
          setQRCodeVisible(false);
          setQRFlag(false);
        }}
        onClose={() => {
          setQRCodeVisible(false);
          setQRFlag(false);
        }}
        showCloseButton
        position="right"
        bodyStyle={{ width: '100%', background: '#1D6AF8' }}
      >
        <div style={{ width: 'inherit' }}>
          <div className={styles.qrcode_detail}>
            <div>
              <div
                style={{
                  width: '100%',
                  background: 'white',
                  borderRadius: '10px',
                }}
              >
                <div>南京维真信息技术有限公司</div>
                <div>
                  ￥<span>{details.price}</span>
                </div>
                <QRCode
                  value={`https://bd.wzyanche.com/order/${details.batchBuyNo}`}
                  // size={300}
                  style={{ width: '78%', height: 'auto' }}
                  fgColor="#000000"
                  // imageSettings={{
                  //   src: logo ,
                  //   height: 30,
                  //   width: 30,
                  //   excavate: true
                  // }}
                />
                <div className={styles.footer}>
                  <img src={微信} alt="" />
                  <img src={支付宝} alt="" />
                </div>
              </div>
            </div>
            <div className={styles.footer_button}>
              <div
                className={styles.order_list_content_footer_0}
                onClick={() => {
                  setQRCodeVisible(false);
                  setQRFlag(false);
                }}
              >
                返回
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Modal
        visible={visibleDetete}
        showCloseButton={true}
        content={
          <div className={styles.forms}>
            <Form form={formDelete}>
              <Form.Item
                name="forms"
                rules={[
                  {
                    required: true,
                    message: '请输入取消原因',
                  },
                ]}
                className={styles.form_item}
              >
                <TextArea
                  placeholder="请输入取消原因"
                  maxLength={100}
                  rows={5}
                  showCount
                  onChange={(v) => {
                    console.log(v);
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        }
        closeOnAction
        onClose={() => {
          setVisibleDetete(false);
        }}
        actions={[
          {
            key: 'confirm',
            text: '确定',
            onClick: () => {
              onDelete(details.batchBuyNo);
            },
          },
          {
            key: 'close',
            text: '取消',
          },
        ]}
      />
      <Modal
        visible={auditsVisible}
        title={'订单审核'}
        showCloseButton={true}
        content={
          <div className={styles.forms}>
            {details &&
              details.details &&
              details.details.map((item: any, index: number) => {
                if (item.giftRemark && item.giftFlag) {
                  return (
                    <Form form={formGift} layout="horizontal" mode="card">
                      <div className={styles.forms_gift}>
                        <div className={styles.forms_gift_0}>备注信息</div>
                        <div className={styles.forms_gift_1}>
                          {item.giftRemark}
                        </div>
                      </div>
                      <div className={styles.forms_gift}>
                        <div className={styles.forms_gift_0}>赠送产品</div>
                        <div>
                          {item.couponName} x {item.num}
                        </div>
                      </div>
                      <Form.Item
                        label={
                          <div className={styles.forms_label}>
                            <span>调整赠送数量</span>
                            <span style={{ color: '#222222' }}>
                              {item.couponName}
                            </span>
                          </div>
                        }
                        name={`num${index}`}
                        className={styles.form_num_1}
                      >
                        <Stepper min={0} max={item.num + upCount} />
                      </Form.Item>
                    </Form>
                  );
                }
              })}
            <div className={styles.footer}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setAuditsVisible(false);
                }}
              >
                取消
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  onFinish();
                }}
              >
                确认
              </Button>
            </div>
          </div>
        }
        closeOnAction
        onClose={() => {
          setAuditsVisible(false);
        }}
        actions={
          [
            // {
            //   key: 'confirm',
            //   text: '确定',
            //   onClick: ()=>{
            //     onFinish()
            //   }
            // },
            // {
            //   key: 'close',
            //   text: '取消',
            // },
          ]
        }
      />
      <Modal
        visible={credentialsVisible}
        closeOnMaskClick
        title={'支付凭证'}
        content={
          <div className={styles.batchBuyNo}>
            <div className={styles.batchBuyNo_img}>
              {details &&
                details.imgProof &&
                details.imgProof.split(',').map((img: any) => {
                  return (
                    <img
                      onClick={() => {
                        setImages(img);
                        setImageVisible(true);
                      }}
                      src={img}
                      alt=""
                    />
                  );
                })}
            </div>
          </div>
        }
        closeOnAction
        onClose={() => {
          setcredentialsVisible(false);
        }}
        actions={[]}
      />
      <ImageViewer
        image={Images}
        visible={imageVisible}
        onClose={() => {
          setImageVisible(false);
        }}
      />
    </div>
  );
};

export default CollectionDetails;
